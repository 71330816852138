import React, { useState } from 'react';
import SidebarContainer from '../../components/Workspace/SideBar/SideBarContainer';
import DashboardContainer from '../../components/Workspace/Dashboard/DashboardContainer';
import UsersModelsContainer from '../../components/Workspace/UsersModels/UsersModelsContainer';
import LibraryContainer from '../../components/Workspace/Library/LibraryContainer';
import AdminUsersContainer from '../../components/Workspace/AdminUsers/AdminUsersContainer';
import TutorialContainer from '../../components/Workspace/Tutorial/TutorialContainer';
import TeamContainer from '../../components/Workspace/Team/TeamContainer';
import AdminBoardContainer from '../../components/Workspace/AdminBoard/AdminBoardContainer';
import AdminDocsContainer from '../../components/Workspace/AdminDocs/AdminDocsContainer';

function Workspace() {
    const [activeComponent, setActiveComponent] = useState('Tableau de bord');

    const renderActiveComponent = () => {
        switch (activeComponent) {
            case 'Tableau de bord':
                return <DashboardContainer />;
            case 'Modèles':
                return <UsersModelsContainer />;
            case 'Bibliothèque':
                return <LibraryContainer />;
            case 'Equipe':
                return <TeamContainer />;
            case 'Utilisateurs':
                return <AdminUsersContainer />;
            case 'Tutoriel':
                return <TutorialContainer />;
            case 'AdminBoard':
                return <AdminBoardContainer />;
            case 'AdminDocs':
                return <AdminDocsContainer />;
            default:
                return <DashboardContainer />;
        }
    };

    return (
        <div className="flex h-full w-full">
            {/* Sidebar */}
            <SidebarContainer setActiveComponent={setActiveComponent} className="h-full" />

            {/* Main Content */}
            <main className="flex-grow p-6 bg-white overflow-auto ">
                {renderActiveComponent()}
            </main>
        </div>
    );
}

export default Workspace;
