// src/pages/DesignSystem.jsx
import React, { useState } from 'react';
import Button from '../../components/style/Button';
import Loader from '../../components/style/Loader';
import Typography from '../../components/style/Typography';

function DesignSystem() {
    const [loadingButton, setLoadingButton] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);

    const handleTestButtonClick = () => {
        setLoadingButton(true);
        setTimeout(() => setLoadingButton(false), 2000); // 2 secondes de chargement
    };

    

    const handleTestButtonDisable = () => {
        setDisabledButton(true);
        setTimeout(() => setDisabledButton(false), 2000); // Désactive le bouton pendant 2 secondes
    };

    return (
        <div className="container mx-auto p-8 space-y-12">
            {/* Header */}
            <h1 className="text-5xl font-bold mb-8">Bara Doc Design System</h1>
            
            {/* Typography Section */}
            <section className="p-4 border rounded-lg shadow-sm">
                <div className="space-y-6">
                    <div className="border-b pb-2">
                      <Typography variant="display">Bara Doc Display</Typography>
                    </div>
                    <div className="border-b pb-2">
                      <Typography variant="h1">Bara Doc h1</Typography>
                      <Typography variant="h1" theme="primary">Bara Doc h1 primary</Typography>
                    </div>
                    <div className="border-b pb-2">
                        <Typography variant="h2">Bara Doc h2</Typography>
                        <Typography variant="h2" theme="secondary">Bara Doc h2 secondary</Typography>
                    </div>
                    <div className="border-b pb-2">
                        <Typography variant="h3">Bara Doc h3</Typography>
                        <Typography variant="h3" theme="gray">Bara Doc h3 gray</Typography>
                    </div>
                    <div className="border-b pb-2">
                        <Typography variant="h4">Bara Doc h4</Typography>
                        <Typography variant="h4" theme="danger">Bara Doc h4 danger</Typography>
                    </div>
                    <div className="border-b pb-2">
                        <Typography variant="h5">Bara Doc h5</Typography>
                        <Typography variant="h5" theme="success">Bara Doc h5 success</Typography>
                    </div>
                    <div className="border-b pb-2">
                        <Typography variant="body-lg">Body Large</Typography>
                        <Typography variant="body-base">Body Base</Typography>
                        <Typography variant="body-sm">Body Small</Typography>
                    </div>
                    <div className="pb-2">
                        <Typography variant="caption1">Caption 1</Typography>
                        <Typography variant="caption2">Caption 2</Typography>
                        <Typography variant="caption3">Caption 3</Typography>
                        <Typography variant="caption4">Caption 4</Typography>
                    </div>
                    <div className="grid grid-cols-4 divide-x divide-gray-300 mt-4 pt-4">
                    {/* Caption 1 */}
                    <div className="border p-2">
                       <Typography variant="caption1" weight="regular">Caption 1</Typography>    
                        <Typography variant="caption1" weight="regular">Regular</Typography>
                        <Typography variant="caption1" weight="medium">Medium</Typography>
                        <Typography variant="caption1" weight="bold">Bold</Typography>
                    </div>

                    {/* Caption 2 */}
                    <div className="border p-2">
                        <Typography variant="caption2" weight="regular">Caption 2</Typography>
                        <Typography variant="caption2" weight="regular">Regular</Typography>
                        <Typography variant="caption2" weight="medium">Medium</Typography>
                        <Typography variant="caption2" weight="bold">Bold</Typography>
                    </div>

                    {/* Caption 3 */}
                    <div className="border p-2">
                        <Typography variant="caption3" weight="regular">Caption 3</Typography>
                        <Typography variant="caption3" weight="regular">Regular</Typography>
                        <Typography variant="caption3" weight="medium">Medium</Typography>
                        <Typography variant="caption3" weight="bold">Bold</Typography>
                    </div>

                    {/* Caption 4 */}
                    <div className="border p-2">
                        <Typography variant="caption4" weight="regular">Caption 4</Typography>
                        <Typography variant="caption4" weight="regular">Regular</Typography>
                        <Typography variant="caption4" weight="medium">Medium</Typography>
                        <Typography variant="caption4" weight="bold">Bold</Typography>
                    </div>
                </div>

                </div>
            </section>

            {/* Buttons Section */}
            <section className="p-4 border rounded-lg shadow-sm">
                <h2 className="text-3xl font-bold mb-4">Buttons</h2>

                {/* Test Button */}
                <div className="border-b pb-2 mb-4">
                    <h3 className="text-xl font-semibold mb-2">Test Buttons</h3>
                    
                    <div className="flex space-x-4">
                        {/* Bouton pour le chargement temporaire */}
                        <Button
                            size="md"
                            variant="primary"
                            isLoading={loadingButton}
                            onClick={handleTestButtonClick}
                        >
                            Click to Load
                        </Button>

                        {/* Bouton pour le mode disabled temporaire */}
                        <Button
                            size="md"
                            variant="primary"
                            disabled={disabledButton} // Utilisez `disabled` au lieu de `isLoading`
                            onClick={handleTestButtonDisable}
                        >
                            Click to Disable
                        </Button>
                    </div>
                </div>

                <div className="space-y-6">
                    
                    {/* Small Buttons */}
                    <div className="border-b pb-4">
                        <h3 className="text-xl font-semibold mb-2">Small</h3>
                        <div className="flex flex-col space-y-2">
                            <div className="flex space-x-4">
                                <Button size="sm" variant="primary">Primary</Button>
                                <Button size="sm" variant="secondary">Secondary</Button>
                                <Button size="sm" variant="tertiary">Tertiary</Button>
                                <Button size="sm" variant="outline">Outline</Button>
                                <Button size="sm" variant="danger">Danger</Button>
                            </div>
                            <div className="flex space-x-4">
                                <Button size="sm" variant="primary" isLoading>Primary</Button>
                                <Button size="sm" variant="secondary" isLoading>Secondary</Button>
                                <Button size="sm" variant="tertiary" isLoading>Tertiary</Button>
                                <Button size="sm" variant="outline" isLoading>Outline</Button>
                                <Button size="sm" variant="danger" isLoading>Danger</Button>
                            </div>
                            <div className="flex space-x-4">
                                <Button size="sm" variant="primary" disabled>Primary</Button>
                                <Button size="sm" variant="secondary" disabled>Secondary</Button>
                                <Button size="sm" variant="tertiary" disabled>Tertiary</Button>
                                <Button size="sm" variant="outline" disabled>Outline</Button>
                                <Button size="sm" variant="danger" disabled>Danger</Button>
                            </div>
                        </div>
                    </div>

                    {/* Medium Buttons */}
                    <div className="border-b pb-4">
                        <h3 className="text-xl font-semibold mb-2">Medium</h3>
                        <div className="flex flex-col space-y-2">
                            <div className="flex space-x-4">
                                <Button size="md" variant="primary">Primary</Button>
                                <Button size="md" variant="secondary">Secondary</Button>
                                <Button size="md" variant="tertiary">Tertiary</Button>
                                <Button size="md" variant="outline">Outline</Button>
                                <Button size="md" variant="danger">Danger</Button>
                            </div>
                            <div className="flex space-x-4">
                                <Button size="md" variant="primary" isLoading>Primary</Button>
                                <Button size="md" variant="secondary" isLoading>Secondary</Button>
                                <Button size="md" variant="tertiary" isLoading>Tertiary</Button>
                                <Button size="md" variant="outline" isLoading>Outline</Button>
                                <Button size="md" variant="danger" isLoading>Danger</Button>
                            </div>
                            <div className="flex space-x-4">
                                <Button size="md" variant="primary" disabled>Primary</Button>
                                <Button size="md" variant="secondary" disabled>Secondary</Button>
                                <Button size="md" variant="tertiary" disabled>Tertiary</Button>
                                <Button size="md" variant="outline" disabled>Outline</Button>
                                <Button size="md" variant="danger" disabled>Danger</Button>
                            </div>
                        </div>
                    </div>

                    {/* Large Buttons */}
                    <div className="pb-4">
                        <h3 className="text-xl font-semibold mb-2">Large</h3>
                        <div className="flex flex-col space-y-2">
                            <div className="flex space-x-4">
                                <Button size="lg" variant="primary">Primary</Button>
                                <Button size="lg" variant="secondary">Secondary</Button>
                                <Button size="lg" variant="tertiary">Tertiary</Button>
                                <Button size="lg" variant="outline">Outline</Button>
                                <Button size="lg" variant="danger">Danger</Button>
                            </div>
                            <div className="flex space-x-4">
                                <Button size="lg" variant="primary" isLoading>Primary</Button>
                                <Button size="lg" variant="secondary" isLoading>Secondary</Button>
                                <Button size="lg" variant="tertiary" isLoading>Tertiary</Button>
                                <Button size="lg" variant="outline" isLoading>Outline</Button>
                                <Button size="lg" variant="danger" isLoading>Danger</Button>
                            </div>
                            <div className="flex space-x-4">
                                <Button size="lg" variant="primary" disabled>Primary</Button>
                                <Button size="lg" variant="secondary" disabled>Secondary</Button>
                                <Button size="lg" variant="tertiary" disabled>Tertiary</Button>
                                <Button size="lg" variant="outline" disabled>Outline</Button>
                                <Button size="lg" variant="danger" disabled>Danger</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>


            {/* Loaders Section */}
            <section className="p-4 border rounded-lg shadow-sm">
            <h2 className="text-3xl font-bold mb-4">Loaders</h2>
            <div className="grid grid-cols-5 gap-4">
                {[
                { size: 'xs', color: 'primary-600', label: 'Extra Small - Primary 600' },
                { size: 'sm', color: 'secondary', label: 'Small - Secondary' },
                { size: 'md', color: 'primary', label: 'Medium - Primary' },
                { size: 'lg', color: 'alert-danger', label: 'Large - Danger' },
                { size: 'xl', color: 'alert-success', label: 'Extra Large - Success' },
                ].map(({ size, color, label }) => (
                <div key={`${size}-${color}`} className="flex flex-col items-center">
                    <Loader size={size} color={color} />
                    <p className="mt-2 text-sm font-medium">{size.toUpperCase()}</p>
                    <p className="text-xs text-gray-600">{label}</p>
                </div>
                ))}
            </div>
            </section>


        </div>
    );
}

export default DesignSystem;
