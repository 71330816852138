// components/Login/LoginModal/index.jsx
import React, { useState } from 'react';
import { Modal } from 'flowbite-react';
import { FaGoogle, FaApple } from 'react-icons/fa';
import { useModal } from '../../../context/Login/ModalContext';
import { useUser } from '../../../context/User/UserContext';
import { useNavigate } from 'react-router-dom';
import Button from '../../style/Button';

import { FcGoogle } from "react-icons/fc";

function LoginModal() {
    const { isModalOpen, closeModal } = useModal();
    const { login } = useUser();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // Liste des utilisateurs de test
    const testUsers = [
        { email: 'maude@example.com', password: '123', name: 'Maude', role: 'user' },
        { email: 'flora@example.com', password: '654123', name: 'Flora', role: 'admin' },
        { email: 'test@example.com', password: '123', name: 'Test User', role: 'user' },
        { email: 'admin@example.com', password: '654123', name: 'Admin User', role: 'admin' },
        { email: 'jl@example.com', password: '123', name: 'Jean-Luc', role: 'admin' },
    ];


    const handleLogin = (e) => {
        e.preventDefault();
        
        // Recherche de l'utilisateur dans la liste des utilisateurs de test
        const user = testUsers.find(
            (u) => u.email === email && u.password === password
        );

        if (user) {
            login(user); // Met à jour le contexte avec les informations de l'utilisateur
            closeModal(); // Ferme la modal après connexion
            setError(''); // Réinitialise les erreurs
            navigate('/workspace');
        } else {
            setError('Email ou mot de passe incorrect');
        }
    };

    return (
        <Modal show={isModalOpen} onClose={closeModal} size="md">
            <Modal.Header>Bienvenue !</Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <p className='mb-2'>Accédez à votre compte en quelques secondes. </p>
                    <p className="mb-4">Pas encore de compte ? <a href="/signup" className="text-blue-500">Inscrivez-vous.</a></p>
                    <form onSubmit={handleLogin}>
                        <input 
                            type="email" 
                            placeholder="name@company.com" 
                            className="border w-full p-2 mb-2" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required 
                        />
                        <input 
                            type="password" 
                            placeholder="Password" 
                            className="border w-full p-2 mb-4" 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required 
                        />
                        <Button 
                            variant='primary' 
                            size='sm' 
                            type="submit"  // Indique que le bouton est de type "submit"
                            className="w-full"  // Pour le rendre large comme le bouton d'origine
                        >
                            Se connecter à votre compte
                        </Button>
                    </form>
                    <div className="flex justify-between items-center mb-4">
                        <span className="text-gray-500">ou</span>
                    </div>
                    <button className="w-full bg-white border border-gray-300 p-2 flex items-center justify-center mb-2">
                        <FcGoogle className="mr-2" /> Se connecter avec Google
                    </button>
                    <button className="w-full bg-white border border-gray-300 p-2 flex items-center justify-center mb-4">
                        <FaApple className="mr-2" /> Se connecter avec Apple
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
