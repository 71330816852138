import React, { useState, useEffect, memo } from "react";
import ModalDocument from "../Modal/ModalDocument";
import Button from "../../style/Button";
import Loader from "../../style/Loader";

function DocumentCarousel({onValidate, documents}) {
    // Liste des chemins vers les images PNG temporaires
    const [currentIndex, setCurrentIndex] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState("");
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
      if (documents && documents.length > 0) {
          setIsLoading(false); // Les documents sont chargés
      } else {
          setIsLoading(true); // En attente des documents
      }
    }, [documents]);

    // Vérifier si des documents sont disponibles
    if (isLoading) {
      return (
          <div className="flex flex-col items-center justify-center h-full">
              <Loader size="md" color="primary" />
              <p className="mt-2 text-lg font-semibold">Chargement...</p>
          </div>
      );
  }

    const currentDocument = documents[currentIndex];
    // Récupérer l'URL de la première image PNG du document
    const imageUrl = currentDocument.document_metadata?.png_urls && currentDocument.document_metadata.png_urls[0]
        ? `${process.env.REACT_APP_API_URL}${currentDocument.document_metadata.png_urls[0]}`
        : null;

    // Fonction pour aller à l'image précédente
    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? documents.length - 1 : prevIndex - 1
        );
    };
    
    // Fonction pour aller à l'image suivante
        const handleNext = () => {
            setCurrentIndex((prevIndex) =>
                prevIndex === documents.length - 1 ? 0 : prevIndex + 1
        );
    };

    const openModal = (type) => {
      setModalType(type);
      setIsModalOpen(true);
  };
    
    const closeModal = () => {
        setIsModalOpen(false);
      };
    // Document actuellement sélectionné
    

    return (
        <div className="bg-gray-50 mx-auto h-full w-full">
          {/* Flèche gauche avec ton chevron */}
          <button
            onClick={handlePrev}
            className="absolute left-16 top-1/2 transform -translate-y-1/2 p-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth="1.5" 
            stroke="currentColor" 
            className="h-24 w-24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          </button>
      
          {/* Image affichée */}
          <div className="flex flex-col justify-center items-center rounded overflow-hidden h-full">
                {isImageLoaded && (
                    <h2 className="text-xl font-bold mb-4 text-center">{currentDocument.name}</h2>
                )}
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt={currentDocument.name}
                        className="object-contain h-[90%] w-auto max-h-full shadow-lg border-primary"
                        onLoad={() => setIsImageLoaded(true)}
                        onError={() => setIsImageLoaded(false)}
                    />
                ) : (
                    <p>Aperçu non disponible</p>
                )}
            </div>
      
          {/* Flèche droite avec ton chevron */}
          <button
            onClick={handleNext}
            className="absolute right-16 top-1/2 transform -translate-y-1/2  p-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" 
            fill="none" viewBox="0 0 24 24" 
            strokeWidth="1.5" stroke="currentColor" 
            className="h-24 w-24">
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </button>

      {/* Boutons pour ajouter un modèle et sélectionner */}
      <div className="absolute bottom-4 right-4 space-y-2 flex flex-col">
          <Button variant="secondary" size="md" onClick={() => openModal("select")}>Selectionner</Button>
          <Button variant="secondary" size="md" onClick={() => openModal("add")}>Nouveau</Button>
      </div>
      
      <ModalDocument 
        isOpen={isModalOpen} 
        closeModal={closeModal}
        mode={modalType}
        document={currentDocument}
        onSubmit={onValidate}
        onOpenModal={openModal}
        />
        </div>
      );
}

export default memo(DocumentCarousel)