import React, {memo} from "react";

function Footer() {
    return (
        <footer className="bg-gray text-white text-center py-4 w-full">
            <p>Créer vos documents n’aura jamais été aussi simple.</p>
            <p>© 2024 Bara Doc. Tous droits réservés.</p>
        </footer>
    );
}

export default memo(Footer);
