import React, { useState, useEffect } from 'react';
import AdminUsersTable from '../AdminUsersTable';
import SidePanel from '../../../SidePanelComponents/SidePanel';
import EditUserForm from '../../../SidePanelComponents/EditUserForm';
import ConfirmationModal from '../../../style/ConfirmationModal';
import AddUserForm from '../../../SidePanelComponents/AddUserForm';
import { fetchAllUsers, registerUser, deleteUser } from '../../../../utils/ApiUsersUtilities';

function AdminUsersContainer() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
    const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);

    const [isConfirming, setIsConfirming] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    // Fonction pour charger les utilisateurs depuis l'API
    const loadUsers = async () => {
        try {
            const usersData = await fetchAllUsers();
            setUsers(usersData); // Mettre à jour la liste des utilisateurs
            console.log("Utilisateurs chargés :", usersData); // Confirmez que les utilisateurs sont chargés
        } catch (error) {
            console.error("Erreur lors du chargement des utilisateurs :", error);
        }
    };

    // Charger les utilisateurs une fois au chargement du composant
    useEffect(() => {
        loadUsers();
    }, []);

    // Gestion de l'ajout d'utilisateur
    const handleAddUser = async (newUser) => {
        console.log("Données envoyées à l'API :", JSON.stringify(newUser)); 
        try {
            const createdUser = await registerUser(newUser); // Utilisation de la fonction importée
            console.log("Utilisateur créé avec succès :", createdUser);
    
            // Rechargez la liste des utilisateurs après la création
            await loadUsers(); 
            setIsAddPanelOpen(false); // Fermer le panneau d'ajout après un ajout réussi
        } catch (error) {
            console.error("Erreur lors de l'ajout de l'utilisateur :", error.message);
        }
    };

    // Gestion de l'édition
    const handleEdit = (user) => {
        setSelectedUser(user);
        setIsEditPanelOpen(true);
    };

    const handleSave = async (updatedUser) => {
        try {
            await loadUsers(); // Recharge les utilisateurs pour obtenir les dernières données depuis le backend
            setIsEditPanelOpen(false); // Fermer le panneau d'édition après la sauvegarde
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'utilisateur :", error.message);
        }
    };
    
    

    const handleDeleteRequest = (user) => {
        console.log("Données utilisateur pour suppression :", user); // Vérifier ici
        if (user && user.id) {
            setUserToDelete(user);
            setIsConfirming(true);
        } else {
            console.error("Erreur : Utilisateur invalide pour suppression.");
        }
    };
    
    
    const confirmDelete = async () => {
        console.log("UserToDelete dans confirmDelete :", userToDelete); // Vérifie que 'userToDelete' est bien défini
        if (userToDelete && userToDelete.id) {
            try {
                await deleteUser(userToDelete.id);
                setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id));
                setIsConfirming(false);
                setUserToDelete(null);
            } catch (error) {
                console.error("Erreur lors de la suppression de l'utilisateur :", error);
            }
        } else {
            console.error("Erreur : Aucun utilisateur sélectionné pour suppression.");
        }
    };
    
    const cancelDelete = () => {
        setIsConfirming(false); // Ferme la modale de confirmation sans supprimer l'utilisateur
        setUserToDelete(null);  // Réinitialise l'utilisateur à supprimer
    };
    

    return (
        <div className="p-6">
            <div className="flex justify-between mb-4">
                <button onClick={() => setIsAddPanelOpen(true)} className="bg-green-500 text-white px-4 py-2 rounded">
                    Ajouter un utilisateur
                </button>
                <button onClick={loadUsers} className="bg-gray-500 text-white px-4 py-2 rounded">
                    Rafraîchir
                </button>
            </div>

            <AdminUsersTable users={users} onEdit={handleEdit} onDelete={(user) => handleDeleteRequest(user)} />


            <SidePanel isOpen={isEditPanelOpen} onClose={() => setIsEditPanelOpen(false)} title="Modifier Utilisateur">
                {selectedUser && (
                    <EditUserForm user={selectedUser} onSave={handleSave} onCancel={() => setIsEditPanelOpen(false)} />
                )}
            </SidePanel>

            <SidePanel isOpen={isAddPanelOpen} onClose={() => setIsAddPanelOpen(false)} title="Ajouter un Utilisateur">
                <AddUserForm onSave={handleAddUser} onCancel={() => setIsAddPanelOpen(false)} />
            </SidePanel>

            <ConfirmationModal
                isOpen={isConfirming}
                title="Confirmation de suppression"
                message={`Voulez-vous vraiment supprimer l'utilisateur "${userToDelete?.name}" ?`}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
            />
        </div>
    );
}

export default AdminUsersContainer;
