import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import DocumentCarousel from '../../components/Home/DocumentCarousel';
import DocumentGenerator from '../../components/Home/DocumentGeneration/DocumentGenerator';
import { GlobalContainer } from '../../components/Containers/GlobalContainer';
import Loader from '../../components/style/Loader';
import { fetchPublicDocuments } from '../../utils/ApiDocsUtilities';
import { useUser } from '../../context/User/UserContext';
import { useNavigate } from 'react-router-dom';
import DocumentManagerHome from '../../components/Home/DocumentManagerHome';

function Home() {
    const [isGenerating, setIsGenerating] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [userChoices, setUserChoices] = useState({});
    const [filteredData, setFilteredData] = useState(null);
    const [listDocument, setListDocument] = useState([]); // État pour stocker les documents récupérés
    const [loading, setLoading] = useState(true); // État pour suivre le chargement

    const { user } = useUser();
    const navigate = useNavigate();

    React.useEffect(() => {
        // Redirige si l'utilisateur est connecté
        if (user) {
            navigate('/workspace');
        }
    }, [user, navigate]);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                setLoading(true); // Activer le mode chargement
                const data = await fetchPublicDocuments();
                setListDocument(data); // Stocker les documents dans l'état
            } catch (error) {
                console.error('Erreur lors de la récupération des documents:', error);
            } finally {
                setLoading(false); // Désactiver le mode chargement après récupération des documents
            }
        };

        fetchDocuments(); // Appeler la fonction dès que la page est chargée
    }, []);

    const handleValidate = (originalDocumentData, filteredDocumentData, choices) => {
        setSelectedDocument(originalDocumentData);
        setFilteredData(filteredDocumentData);
        setUserChoices(choices);
        setIsGenerating(true); // Active le mode génération
    };
    
    // Fonction pour retourner au carrousel des documents
    const handleBackToCarousel = () => {
        setIsGenerating(false);
        setSelectedDocument(null);
        setUserChoices({});
    };

    return (
        <div className="bg-primary-200 flex flex-col min-h-screen">
            
            {/* Conteneur principal qui s'étire pour occuper l'espace disponible */}
            <div className="flex-grow">
                <GlobalContainer>
                    <DocumentManagerHome/>
                </GlobalContainer>
            </div>
    
            {/* Footer sticky en bas de la page */}
            <footer className="bg-black text-white w-full">
                <Footer />
            </footer>
        </div>
    );
    
}

export default Home;
