import React from 'react';

function HowItWorks() {

    /*
    <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">2. 🏢 Champs de l'Entreprise pour le Remplissage Automatique : <code>[[valeur (entreprise)]]</code></h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour insérer des informations relatives à l'entreprise, comme le nom de l'entreprise, l'adresse, ou le numéro de téléphone.</p>
                <p><strong>Comment l'écrire ?</strong> : Entourez le nom du champ par deux crochets doubles et ajoutez <code>(entreprise)</code> à la fin. Exemple : <code>[[Nom de l'entreprise (entreprise)]]</code>.</p>
                <p><strong>Fonctionnement :</strong> Ces champs seront automatiquement remplis avec les informations de l'entreprise lors de la génération du document.</p>
                <p><em>Note :</em> Ce format fonctionne également en anglais en utilisant <code>(enterprise)</code>. Exemple : <code>[[Company Name (enterprise)]]</code>.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">3. 👤 Champs du Salarié pour le Remplissage Automatique : <code>[[valeur (salarié)]]</code> ou <code>[[valeur (employee)]]</code></h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour insérer des informations spécifiques au salarié, telles que le nom, l'adresse, le poste, etc.</p>
                <p><strong>Comment l'écrire ?</strong> : Entourez le nom du champ par deux crochets doubles et ajoutez <code>(salarié)</code> à la fin. Exemple : <code>[[Nom du salarié (salarié)]]</code>.</p>
                <p><strong>Fonctionnement :</strong> Ces champs seront automatiquement remplis avec les informations du salarié lors de la génération du document.</p>
                <p><em>Note :</em> Vous pouvez également utiliser le format anglais <code>(employee)</code> pour indiquer les champs du salarié. Exemple : <code>[[Employee Name (employee)]]</code>.</p>
            </div>
    */ 
    return (
        <div className="container mx-auto px-6 py-12">
            <h2 className="text-3xl font-bold mb-6">Comment ça marche ?</h2>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">1. ✏️ Champ de Texte Court : <code>[[valeur]]</code></h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour des champs courts comme le nom, l'adresse, ou un intitulé.</p>
                <p><strong>Comment l'écrire ?</strong> : Entourez le nom du champ par deux crochets doubles. Exemple : <code>[[Nom]]</code>.</p>
                <p><strong>Fonctionnement :</strong> L'utilisateur verra un champ de texte à remplir avec la valeur souhaitée.</p>
                <p><em>Note :</em> Évitez d'utiliser le caractère <code>*</code> dans les champs de texte court pour éviter les conflits avec les champs de texte long (Text Area).</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">2. 👫 Choix du Genre : <code>[[valeur 1 / valeur 2]]</code></h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour indiquer un genre, comme "Madame" ou "Monsieur".</p>
                <p><strong>Comment l'écrire ?</strong> : Placez deux valeurs séparées par une barre oblique <code>/</code>. Exemple : <code>[[Madame / Monsieur]]</code>.</p>
                <p><strong>Fonctionnement :</strong> L'outil affichera un bouton radio pour choisir entre les deux options. Une fois choisi, ce genre s'appliquera automatiquement à tous les éléments similaires dans le document.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">3. 📝 Champ de Texte Long : <code>[[*texte à insérer]]</code></h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour des champs de texte plus longs, comme une description ou un commentaire.</p>
                <p><strong>Comment l'écrire ?</strong> : Utilisez la syntaxe spéciale en commençant avec le caractère <code>*</code>. Exemple : <code>[[*Description du projet]]</code>.</p>
                <p><strong>Fonctionnement :</strong> L'utilisateur aura une grande zone de texte pour rédiger des paragraphes ou des notes détaillées.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">4. ✅ Sélection parmi des Choix : <code>[[choix 1 | choix 2 | choix 3]]</code></h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour proposer des choix, comme "Oui", "Non" ou d'autres options spécifiques.</p>
                <p><strong>Comment l'écrire ?</strong> : Séparez les options par une barre verticale <code>|</code> entre des crochets doubles. Exemple : <code>[[CDI | CDD | Freelance]]</code>.</p>
                <p><strong>Fonctionnement :</strong> L'utilisateur verra une liste de boutons radio pour sélectionner une des options proposées.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">5. 📋 Options Conditionnelles : <code>#option 1 : titre de l'option# ... ##</code></h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour insérer des sections de texte qui ne s'affichent que si une option est sélectionnée.</p>
                <p><strong>Comment l'écrire ?</strong> : Commencez par <code>#option X : titre de l'option#</code> et terminez par <code>##</code>. Exemple :</p>
                <pre><code>#option 1 : Courrier remis en main propre# Votre texte ici... ##</code></pre>
                <p><strong>Fonctionnement :</strong> Si l'utilisateur choisit cette option, le texte et tous les champs à l'intérieur s'afficheront dans le document.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">6. 🗒️ Champs dans les Options :</h3>
                <p><strong>Quand l'utiliser ?</strong> : Lorsque vous souhaitez inclure des champs (texte, genre, etc.) à l'intérieur d'une option conditionnelle.</p>
                <p><strong>Comment ça fonctionne ?</strong> : Tout champ (texte, genre, etc.) placé à l'intérieur d'une option conditionnelle ne s'affichera que si l'option est sélectionnée. Cela permet de personnaliser davantage le document en fonction des choix de l'utilisateur.</p>
                <p>Exemple :</p>
                <pre><code>#option 1 : Le salarié s'est présenté# Suite à notre entretien qui s'est tenu le [[date de convocation]], nous avons décidé... ##</code></pre>
                <p>Ici, le champ <code>[[date de convocation]]</code> ne s'affichera que si cette option est choisie.</p>
            </div>
        </div>
    );
}

export default HowItWorks;
