import React from 'react';

function SideBarComponent({ icon, name, onSelect, isSidebarOpen }) {
    return (
        <div 
            onClick={onSelect} 
            className={`flex items-center ${isSidebarOpen ? 'space-x-2' : 'justify-center'} cursor-pointer text-gray-700 hover:text-primary-600 p-2`}
        >
            {icon && <span className="text-lg">{icon}</span>}
            {isSidebarOpen && <span>{name}</span>}
        </div>
    );
}

export default SideBarComponent;
