import React from 'react';
import Loader from '../../style/Loader';
import Typography from '../../style/Typography';
import Button from '../../style/Button';

import { IoAddOutline } from "react-icons/io5"; // Correction de l'importation ici
import { FaMagnifyingGlass } from "react-icons/fa6";
import { MdEditDocument } from "react-icons/md";

function DocumentTableHome({ documents, loading, onSelectDocument, onOpenModal }) {
    return (
        <div className="h-full bg-white p-4 flex flex-col overflow-auto">
            {/* Conteneur des boutons d'action au-dessus du tableau */}
            <div className="flex justify-between items-center mb-4">
                <Button 
                    variant="terciary" 
                    size="sm" 
                    icon={IoAddOutline} 
                    iconPosition="right" 
                    onClick={() => console.log("Nouveau")}
                >
                    Nouveau
                </Button>
            </div>

            {loading ? (
                <Loader size="lg" />
            ) : (
                <table className="w-full table-fixed">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="px-4 py-3 text-left w-2/5">
                                <Typography variant="body-base" weight="bold">Nom</Typography>
                            </th>
                            <th className="px-4 py-3 text-left w-1/5">
                                <Typography variant="body-base" weight="bold">Catégorie</Typography>
                            </th>
                            <th className="px-4 py-3 text-center w-1/6">
                                <Typography variant="body-base" weight="bold">Aperçu</Typography>
                            </th>
                            <th className="px-4 py-3 text-center w-1/6">
                                <Typography variant="body-base" weight="bold">Sélectionner</Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map((document, index) => (
                            <tr
                                key={index}
                                className="border-t hover:bg-gray-100 cursor-pointer"
                                onClick={() => onSelectDocument(document)}
                            >
                                <td className="px-4 py-4 text-base truncate">
                                    <Typography variant="body-sm">{document.name}</Typography>
                                </td>
                                <td className="px-4 py-4 text-base truncate">
                                    <Typography variant="body-sm">{document.category}</Typography>
                                </td>
                                <td className="px-4 py-4 text-center">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onOpenModal(document);
                                        }}
                                        className="w-8 h-8 flex items-center justify-center rounded-full text-primary hover:text-primary-700"
                                    >
                                        <FaMagnifyingGlass size={18} />
                                    </button>
                                </td>
                                <td className="px-4 py-4 text-center">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            console.log("Document sélectionné :", document);
                                        }}
                                        className="w-8 h-8 flex items-center justify-center rounded-full text-gray-400 hover:text-primary-700"
                                    >
                                        <MdEditDocument size={18} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default DocumentTableHome;
