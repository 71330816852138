import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './styles/global.css';  // Assurez-vous que cet import est correct
import Home from './pages/Home'
import HowItWorks from './pages/HowItWorks';
import FeaturesToAdd from './pages/FeaturesToAdd';
import DesignSystem from './pages/Design-system';
import Workspace from './pages/Workspace';
import Header from './components/Header';
import { UserProvider } from './context/User/UserContext';
import { ModalProvider } from './context/Login/ModalContext';
import LoginModal from './components/Login/LoginModal';

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <ModalProvider>
        <Router>
        <div className="flex flex-col h-screen w-full">
            <Header />
            <div className="flex flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/features-to-add" element={<FeaturesToAdd />} />
                <Route path="/design-system" element={<DesignSystem />} />
                <Route path="/workspace" element={<Workspace />} />
              </Routes>
              <LoginModal />
            </div>
          </div>
        </Router>
      </ModalProvider>
    </UserProvider>
    
  </React.StrictMode>,
  document.getElementById('root')
)