// src/components/Typography.js
import React from 'react';
import PropTypes from 'prop-types';

const combineClasses = (...classes) => {
    return classes.filter(Boolean).join(' ');
};

function Typography({
    variant = "h3",
    component: Component = "div",
    theme = "black",
    weight = "regular",
    className = "",
    children,
}) {
    let variantStyles = "", colorStyles = "";

    switch (variant) {
        case "display":
            variantStyles = "text-8xl md:text-6xl sm:text-4xl"; // Responsive tailles
            break;
        case "h1":
            variantStyles = "text-7xl md:text-5xl sm:text-3xl";
            break;
        case "h2":
            variantStyles = "text-6xl md:text-4xl sm:text-2xl";
            break;
        case "h3":
            variantStyles = "text-5xl md:text-3xl sm:text-xl";
            break;
        case "h4":
            variantStyles = "text-4xl md:text-2xl sm:text-lg";
            break;
        case "h5":
            variantStyles = "text-3xl md:text-xl sm:text-lg";
            break;
        case "lead":
            variantStyles = "text-2xl md:text-lg sm:text-base";
            break;
        case "body-lg":
            variantStyles = "text-lg md:text-base sm:text-sm";
            break;
        case "body-base":
            variantStyles = "text-base md:text-sm sm:text-xs";
            break;
        case "body-sm":
            variantStyles = "text-sm md:text-xs sm:text-xs";
            break;
        case "caption1":
            variantStyles = "text-base md:text-sm sm:text-xs";
            break;
        default:
            variantStyles = "text-base";
    }

    switch (theme) {
        case "black":
            colorStyles = "text-black";
            break;
        case "gray":
            colorStyles = "text-gray-700";
            break;
        default:
            colorStyles = "text-black";
    }

    return (
        <Component
            className={combineClasses(
                variantStyles,
                colorStyles,
                weight === "medium" && "font-medium",
                weight === "bold" && "font-bold",
                className
            )}
        >
            {children}
        </Component>
    );
}

Typography.propTypes = {
    variant: PropTypes.oneOf([
        "display", "h1", "h2", "h3", "h4", "h5", 
        "lead", "body-lg", "body-base", "body-sm", 
        "caption1"
    ]),
    component: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "div", "p", "span"]),
    theme: PropTypes.oneOf([
        "black", "gray", "gray-600", "white", 
        "primary", "secondary", "danger", "success", "warning"
    ]),
    weight: PropTypes.oneOf(["regular", "medium", "bold"]),
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Typography;
