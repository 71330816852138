// Header.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiBell, FiHelpCircle, FiChevronDown, FiUser } from 'react-icons/fi';
import { useModal } from '../../context/Login/ModalContext';
import { useUser } from '../../context/User/UserContext';
import IconButton from '../style/IconButton';
import { useNavigate } from 'react-router-dom';
import Button from '../style/Button';

function Header() {
    const { user, logout } = useUser();
    const { openModal } = useModal();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    // Fermer le dropdown quand on clique à l'extérieur
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleLogout = () => {
        logout();
        navigate('/'); // Redirige vers la page d'accueil après la déconnexion
    };

    return (
        <header className='sticky top-0 shadow-sm py-4 bg-white z-10'>
            <div className='container mx-auto px-6 flex justify-between items-center'>
                {/* Logo à gauche */}
                <div className="flex items-center space-x-2">
                    <h1 className="text-2xl font-bold tracking-wide"><Link to="/">Bara Doc</Link></h1>
                </div>

                {/* Si l'utilisateur est connecté */}
                {user ? (
                    <>
                        {/* Message de bienvenue au centre */}
                        <div className="hidden sm:flex flex-grow justify-center text-lg font-semibold text-gray-700">
                            Hello, {user.name} !
                        </div>

                        {/* Icônes et Dropdown à droite */}
                        <div className="flex items-center space-x-6">
                            {/* Icônes de notifications et paramètres visibles pour les utilisateurs connectés */}
                            <div className="hidden md:flex space-x-4">
                                <IconButton icon={FiBell} size={20} />
                                <IconButton icon={FiHelpCircle} size={20} />
                            </div>

                            {/* Dropdown de profil avec icône par défaut */}
                            <div className="relative" ref={dropdownRef}>
                                <button onClick={toggleDropdown} className="flex items-center space-x-2 text-gray-600 hover:text-primary focus:outline-none">
                                    {user?.avatar ? (
                                        <img src={user.avatar} alt="avatar" className="w-8 h-8 rounded-full" />
                                    ) : (
                                        <FiUser size={24} className="w-8 h-8 rounded-full text-gray-500" />
                                    )}
                                    <FiChevronDown size={20} />
                                </button>

                                {/* Dropdown content */}
                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border z-20">
                                        <div className="px-4 py-3 border-b">
                                            <p className="text-sm font-semibold">{user?.name}</p>
                                            <p className="text-xs text-gray-500">{user?.email}</p>
                                        </div>
                                        <Link to="/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            Paramètres
                                        </Link>
                                        <Link to="/earnings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            Gérer mon abonnement
                                        </Link>
                                        <button
                                            onClick={handleLogout}
                                            className="w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-gray-100 border-t"
                                        >
                                            Déconnexion
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    /* Si l'utilisateur n'est pas connecté */
                    <nav className="flex items-center space-x-6">
                        <Link to="/how-it-works" className="hover:text-primary">Comment ça marche ?</Link>
                        <Link to="/design-system" className="hover:text-primary">Design-System</Link>
                        <Button size='sm' onClick={openModal}>
                            Connexion
                        </Button>
                    </nav>
                )}
            </div>
        </header>
    );
}

export default Header;
