import React from 'react';

function LibraryComponent({ category, docs }) {
    return (
        <div className="p-4 border rounded shadow-sm mb-4">
            <h3 className="font-bold mb-2">{category}</h3>
            <ul>
                {docs.map((doc, index) => (
                    <li key={index} className="mb-1">{doc}</li>
                ))}
            </ul>
        </div>
    );
}

export default LibraryComponent;
