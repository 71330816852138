// src/components/DocumentManagerHome.js
import React, { useState, useEffect } from 'react';
import Loader from '../../style/Loader';
import { fetchPublicDocuments } from '../../../utils/ApiDocsUtilities';
import ImagesModal from '../../Modals/ImagesModal';
import DocumentTableHome from '../DocumentTableHome';
import DocumentPreviewHome from '../DocumentPreviewHome';

function DocumentManagerHome() {
    const [listDocument, setListDocument] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                setLoading(true);
                const data = await fetchPublicDocuments();
                setListDocument(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des documents:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchDocuments();
    }, []);

    const handleSelectDocument = (document) => {
        setSelectedDocument(document);
    };

    const handleOpenModal = (document) => {
        setSelectedDocument(document);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const getImageUrl = (path) => {
        return path ? `${process.env.REACT_APP_API_URL}${path}` : null;
    };

    return (
        <div className="flex w-full h-full bg-white rounded-lg overflow-hidden">
            {/* Partie gauche : Tableau des documents avec une bordure à droite */}
            <div className="w-2/4 h-full p-2 overflow-auto border-r border-gray-100">
                <DocumentTableHome
                    documents={listDocument}
                    loading={loading}
                    onSelectDocument={handleSelectDocument}
                    onOpenModal={handleOpenModal}
                />
            </div>

            {/* Partie droite : Aperçu du document */}
            <div className="w-2/4 h-full p-2">
                <DocumentPreviewHome document={selectedDocument} getImageUrl={getImageUrl} />
            </div>

            {/* Modal pour afficher toutes les images */}
            <ImagesModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                images={selectedDocument && selectedDocument.document_metadata ? selectedDocument.document_metadata.png_urls.map(getImageUrl) : []}
            />
        </div>
    );
}

export default DocumentManagerHome;
