// src/components/DocumentPreviewHome.js
import React from 'react';
import Typography from '../../style/Typography';

function DocumentPreviewHome({ document, getImageUrl, className }) {
    return (
        <div className={`${className} h-full bg-white p-4 flex flex-col`}>
            {document ? (
                <>
                    {/* Titre de l'aperçu avec une taille réduite */}
                    <Typography variant="lead" weight="bold" className="mb-4 text-center">
                        Aperçu de {document.name}
                    </Typography>

                    {/* Conteneur d'image maximisé avec overflow-hidden */}
                    <div className="flex-grow w-full h-full bg-gray-100 border border-gray-300 rounded-lg overflow-hidden flex items-center justify-center shadow-lg">
                        <img
                            src={getImageUrl(document.document_metadata.png_urls[0])}
                            alt="Aperçu du document"
                            className="w-full h-full object-cover" // Utilisation de object-cover pour remplir le conteneur et rognage si nécessaire
                        />
                    </div>
                </>
            ) : (
                <Typography variant="body-lg" theme="gray" className="text-lg">
                    Sélectionnez un document pour voir les détails.
                </Typography>
            )}
        </div>
    );
}

export default DocumentPreviewHome;
