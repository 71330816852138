import React, { useState } from 'react';
import SideBarComponent from '../SideBarComponent';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../context/User/UserContext';

import { FiHome, FiBookOpen, FiUsers, FiSettings, FiLogOut } from 'react-icons/fi';
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdAccountCircle, MdOutlineOndemandVideo } from "react-icons/md";
import { GrDocument } from "react-icons/gr";
import { FaChalkboardUser } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { SlDocs } from "react-icons/sl";
import { RxDashboard } from "react-icons/rx";


function SidebarContainer({ setActiveComponent }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { user, logout } = useUser()
    const navigate = useNavigate(); // Utilisation de useNavigate pour la redirection

    const handleLogout = () => {
        logout(); // Déconnexion
        navigate('/'); // Redirection vers la page d'accueil
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <aside className={`bg-primary-200 h-full flex flex-col justify-between shadow-md 
            ${isSidebarOpen ? 'w-56' : 'w-16'}
         transition-all duration-600`}>


            {/* Toggle Button pour ouvrir/fermer la sidebar */}
            <div className="p-4 flex justify-end">
                <button onClick={toggleSidebar} className="text-gray-600 hover:text-gray-800 focus:outline-none">
                    {isSidebarOpen ? <MdOutlineKeyboardDoubleArrowLeft size={24} /> : <MdOutlineKeyboardDoubleArrowRight size={24} />}
                </button>
            </div>

            {/* Section supérieure avec les liens principaux */}
            <nav className={`p-6 space-y-2 flex-grow ${isSidebarOpen ? '' : 'items-center'}`}>
                <SideBarComponent 
                    icon={<RxDashboard />} 
                    name={isSidebarOpen ? "Tableau de bord" : ""} 
                    onSelect={() => setActiveComponent('Tableau de bord')} 
                    isSidebarOpen={isSidebarOpen}
                />
                <SideBarComponent 
                    icon={<GrDocument />} 
                    name={isSidebarOpen ? "Mes modèles" : ""} 
                    onSelect={() => setActiveComponent('Modèles')} 
                    isSidebarOpen={isSidebarOpen}
                />
                <SideBarComponent 
                    icon={<FiBookOpen />} 
                    name={isSidebarOpen ? "Bibliothèque" : ""} 
                    onSelect={() => setActiveComponent('Bibliothèque')} 
                    isSidebarOpen={isSidebarOpen}
                />
                <SideBarComponent 
                        icon={<FaUsers />} 
                        name={isSidebarOpen ? "Equipe" : ""} 
                        onSelect={() => setActiveComponent('Equipe')} 
                        isSidebarOpen={isSidebarOpen}
                    />
                
                <SideBarComponent 
                    icon={<MdOutlineOndemandVideo />} 
                    name={isSidebarOpen ? "Tutoriel" : ""} 
                    onSelect={() => setActiveComponent('Tutoriel')} 
                    isSidebarOpen={isSidebarOpen}
                />
            </nav>
            
            <div className="flex flex-col space-y-1 p-3 border-t border-gray-200">
                {/* Afficher "Utilisateurs" seulement si l'utilisateur est admin */}
                {user?.role === 'admin' && (
                    <SideBarComponent 
                        icon={<FaChalkboardUser />} 
                        name={isSidebarOpen ? "AdminBoard" : ""} 
                        onSelect={() => setActiveComponent('AdminBoard')} 
                        isSidebarOpen={isSidebarOpen}
                    />
                )}

                {user?.role === 'admin' && (
                    <SideBarComponent 
                        icon={<SlDocs />} 
                        name={isSidebarOpen ? "AdminDocs" : ""} 
                        onSelect={() => setActiveComponent('AdminDocs')} 
                        isSidebarOpen={isSidebarOpen}
                    />
                )}

                {user?.role === 'admin' && (
                    <SideBarComponent 
                        icon={<FiUsers />} 
                        name={isSidebarOpen ? "Utilisateurs" : ""} 
                        onSelect={() => setActiveComponent('Utilisateurs')} 
                        isSidebarOpen={isSidebarOpen}
                    />
                )}
                
                
            </div>

            {/* Section inférieure avec Compte, Paramètres, et Déconnexion */}
            <div className="flex flex-col space-y-1 p-3 border-t border-gray-200">
                <SideBarComponent 
                    icon={<MdAccountCircle />} 
                    name={isSidebarOpen ? "Compte" : ""} 
                    onSelect={() => setActiveComponent('Compte')} 
                    isSidebarOpen={isSidebarOpen}
                />
                <SideBarComponent 
                    icon={<FiSettings />} 
                    name={isSidebarOpen ? "Paramètres" : ""} 
                    onSelect={() => setActiveComponent('Paramètres')} 
                    isSidebarOpen={isSidebarOpen}
                />
                {/* Bouton de déconnexion en rouge */}
                <SideBarComponent 
                    icon={<FiLogOut />} 
                    name={isSidebarOpen ? "Déconnexion" : ""} 
                    onSelect={handleLogout}
                    isSidebarOpen={isSidebarOpen}
                />
            </div>
        </aside>
    );
}

export default SidebarContainer;
