// src/components/IconButton/index.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function IconButton({ icon: Icon, to, onClick, size = 24, color = 'text-gray-600', hoverColor = 'hover:text-primary' }) {
    const buttonClasses = `focus:outline-none ${color} ${hoverColor}`;

    return (
        <div className="flex items-center">
            {to ? (
                <Link to={to} className={buttonClasses}>
                    <Icon size={size} />
                </Link>
            ) : (
                <button onClick={onClick} className={buttonClasses}>
                    <Icon size={size} />
                </button>
            )}
        </div>
    );
}

IconButton.propTypes = {
    icon: PropTypes.elementType.isRequired, // Icon component (like FiBell)
    to: PropTypes.string, // Path to navigate on click
    onClick: PropTypes.func, // Click handler for button action
    size: PropTypes.number, // Size of the icon
    color: PropTypes.string, // Default color of the icon
    hoverColor: PropTypes.string, // Hover color of the icon
};

export default IconButton;
