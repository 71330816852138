import React, { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export function UserProvider({ children }) {
    const [user, setUser] = useState(() => {
        // Charger l'utilisateur du localStorage si disponible
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    useEffect(() => {
        // Charger l'utilisateur du localStorage si disponible
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            setUser(JSON.parse(savedUser));
        }
    }, []);

    const login = (userData) => {
        // Ajoute des données simulées pour le test (par ex., entité, entreprises)
        const enrichedUserData = {
            ...userData,
            role: userData.role || 'user', // Ajoute un rôle si non spécifié
            entity: userData.entity || {
                name: 'Entité de Test',
                id: 'ent1',
                companies: [
                    { name: 'Entreprise A', id: 'comp1' },
                    { name: 'Entreprise B', id: 'comp2' },
                ],
            },
        };
        
        setUser(enrichedUserData);
        localStorage.setItem('user', JSON.stringify(enrichedUserData)); // Sauvegarde dans localStorage
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('user'); // Supprimer du localStorage lors de la déconnexion
    };

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    return useContext(UserContext);
}
