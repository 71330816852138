import React from 'react';
import LibraryComponent from '../LibraryComponent';

function LibraryContainer() {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Bibliothèque</h2>
            {/* Ajoute chaque catégorie de document ici */}
            <LibraryComponent category="Ressources humaines" docs={["Doc1", "Doc2", "Doc3"]} />
            <LibraryComponent category="Commercial" docs={["Doc4", "Doc5", "Doc6"]} />
            {/* Ajouter d'autres catégories si nécessaire */}
        </div>
    );
}

export default LibraryContainer;
