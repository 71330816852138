import clsx from "clsx";

export const GlobalContainer = ({ children, className }) => {
    return (
        <div className={clsx(
            className,
            "w-full max-w-7xl mx-auto px-4 lg:px-8"
        )}>
            {children}
        </div>
    );
};
