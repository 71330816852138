export const deleteDocument = async (documentId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/${documentId}`, {
        method: 'DELETE',
    });

    if (!response.ok) {
        throw new Error('Erreur lors de la suppression du document.');
    }

    return await response.json();
};



// Admin
export const fetchPublicDocuments = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/public-documents`);
    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des documents publics.');
    }
    const data = await response.json(); // Ajoute la conversion en JSON
    console.log("FECTHPUBLICDOCUMENT Documents publics reçus (données JSON):", data);
    return data;
};


export const updateDocument = async (documentId, updatedData) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/${documentId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Erreur lors de la mise à jour du document');
    }

    return await response.json();
};

export const getDocument = async (documentId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/${documentId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Erreur lors de la récupération du document');
    }

    return await response.json();
};
