import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import clsx from 'clsx';

function Button({ 
    variant = 'primary', 
    size = 'md', 
    children, 
    onClick, 
    disabled, 
    isLoading, 
    className = '', 
    type = 'button', // Ajoute la prop type avec "button" comme valeur par défaut
    icon: Icon, // Utilisation de la prop "Icon" pour passer le composant d'icône
    iconPosition = 'left', // Position de l'icône: gauche ou droite
    iconSize = 20, // Taille de l'icône
}) {
    // Styles conditionnels basés sur la prop `variant`
    let variantClass = '';
    let loaderColor = '';

    switch (variant) {
        case 'primary':
            variantClass = 'bg-primary-600 text-white rounded hover:bg-primary-400 shadow-md';
            loaderColor = 'white';
            break;
        case 'secondary':
            variantClass = 'bg-primary-200 shadow-md hover:shadow-lg transition-shadow font-semibold rounded text-primary-600';
            loaderColor = 'primary-600';
            break;
        case 'terciary':
            variantClass = 'bg-secondary shadow-md hover:shadow-lg transition-shadow font-semibold rounded text-white';
            loaderColor = 'secondary';
            break;
        case 'outline':
            variantClass = 'border border-primary-600 text-primary-600 hover:bg-primary-100';
            loaderColor = 'primary-600';
            break;
        case 'danger':
            variantClass = 'bg-red-600 text-white hover:bg-red-500';
            loaderColor = 'white';
            break;
        default:
            variantClass = 'bg-primary-600 text-white hover:bg-primary-500';
            loaderColor = 'white';
    }

    // Styles conditionnels basés sur la prop `size`
    let sizeClass = '';
    switch (size) {
        case 'sm':
            sizeClass = 'text-caption3 font-medium px-[14px] py-[11px]';
            break;
        case 'md':
            sizeClass = 'text-caption2 font-medium px-[18px] py-[13px]';
            break;
        case 'lg':
            sizeClass = 'text-caption1 font-medium px-[22px] py-[15px]';
            break;
        default:
            sizeClass = 'text-caption2 font-medium px-[18px] py-[13px]';
    }

    // Classe de désactivation
    const disabledClass = (disabled || isLoading) ? 'opacity-50 cursor-not-allowed' : '';

    // Classes finales pour le bouton
    const buttonClasses = clsx(
        'relative flex items-center justify-center rounded focus:outline-none',
        variantClass,
        sizeClass,
        disabledClass,
        className
    );

    return (
        <button 
            onClick={onClick} 
            className={buttonClasses} 
            disabled={disabled || isLoading} 
            type={type}
        >
            {/* Affichage du loader seulement en mode isLoading */}
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <Loader size="sm" color={loaderColor} />
                </div>
            )}

            {/* Contenu du bouton */}
            <div className={isLoading ? "invisible flex items-center gap-2" : "flex items-center gap-2"}>
                {Icon && iconPosition === 'left' && <Icon size={iconSize} className="mr-1" />}
                {children && <span>{children}</span>}
                {Icon && iconPosition === 'right' && <Icon size={iconSize} className="ml-1" />}
            </div>

        </button>
    );
}

Button.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'outline', 'danger']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    icon: PropTypes.elementType, // Pour passer le composant d'icône (ex: IoAddOutline)
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconSize: PropTypes.number,
};

export default Button;
