import React from 'react';

function ImagesModal({ isOpen, onClose, images }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-4 max-w-3xl w-full">
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700 self-end mb-4">
                    Fermer
                </button>
                <div className="space-y-4">
                    {images.map((url, index) => (
                        <div key={index} className="w-full h-64 bg-gray-200 flex items-center justify-center">
                            <img
                                src={url}
                                alt={`Aperçu ${index + 1}`}
                                className="max-h-full object-contain"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ImagesModal;
