// ApiUsersUtilities.jsx
export const registerUser = async (userData) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });
    
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Erreur lors de la création de l\'utilisateur');
    }
    
    return await response.json();  // Retourner les données de la réponse
};


export const fetchAllUsers = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
            method: 'GET',
            credentials: 'include', // Cela inclut les cookies dans la requête
        });
        
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des utilisateurs');
        }
        
        return await response.json();
    } catch (error) {
        console.error("Erreur d'appel API pour récupérer les utilisateurs :", error);
        throw error;
    }
};

export const deleteUser = async (userId) => {
    console.log("ID de l'utilisateur à supprimer :", userId); // Vérification du userId
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Erreur lors de la suppression de l\'utilisateur');
    }

    return true; // Indiquer que la suppression a réussi
};

export const updateUser = async (userId, updatedUser) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUser),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Erreur lors de la mise à jour de l\'utilisateur');
    }

    return await response.json();
};
